<template>
    <v-row class="gradient-background" v-if="$vuetify.display.xs || $vuetify.display.sm">
        <v-col cols="4">
          <router-link to="/">
            <v-img to="/" height="100" width="400"  class="mt-4 ml-2" cover src="../assets/gckLogo1.png" ></v-img>
          </router-link>
        </v-col>
        <v-col cols="8" class="text-left">
            <h2 class="mt-4 " style="color: rgb(2, 17, 2);">राजकीय महाविद्यालय करौली</h2>
            <h2 class="" style="color: rgb(5, 75, 5);">Government College Karauli</h2>
        </v-col>
        <v-col cols="12">
            <v-toolbar color="green-darken-3">
                <v-app-bar-nav-icon @click="$store.state.navigationDrawer = true"></v-app-bar-nav-icon>
                <!-- <marquee behavior="scroll" direction="left">
                  <h4>
                    Admission open for session 2023-2024
                  </h4>
                </marquee> -->
            </v-toolbar>
        </v-col>
    </v-row>
    <v-row v-else class="gradient-background">
        <v-col cols="1"></v-col>
        <v-col cols="1">
          <router-link to="/">
            <v-img width="200" height="100" class="mt-3 ml-16" to="/" src="../assets/gckLogo1.png" ></v-img>
          </router-link>
        </v-col>
        <v-col cols="6">
            <h1 class="ml-16 mt-4" style="color: rgb(2, 17, 2);">राजकीय महाविद्यालय करौली</h1>
            <h1 class="ml-16" style="color: rgb(5, 75, 5);">Government College Karauli</h1>
        </v-col>
        <v-col cols="4"></v-col>
        <v-toolbar color="green-darken-3"   class="scrollable-toolbar">
            <v-spacer></v-spacer>
            <v-btn to="/" stacked style="font-size: 12px;">Home</v-btn>
            <v-menu open-on-hover v-model="aboutUsFlag">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" height="65" density="compact" :append-icon="aboutUsFlag ? 'mdi-chevron-up':'mdi-chevron-down'" style="font-size: 12px;">About Us</v-btn>
              </template>
              <v-list style="background-color:rgb(242, 255, 242);">
                    <v-list-item @click="updateMenu(item)" v-for="(item, index) in aboutUsMenu" :key="index" link>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                      <template v-slot:append v-if="item.subMenuFlag">
                      <v-icon icon="mdi-menu-right" size="x-small"></v-icon>
                    </template>
                      <v-menu :open-on-focus="false" activator="parent" open-on-hover submenu location="right" v-if="item.subMenuFlag">
                        <v-list style="background-color:rgb(242, 255, 242);">
                          <v-list-item @click="updateMenu(subMenuItem)" v-for="(subMenuItem,j) in item.subMenuContent" :key="j" link>
                            <v-list-item-title>{{ subMenuItem.title }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item>
              </v-list>
            </v-menu>
            <v-menu open-on-hover v-model="academicFlag" >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" height="65" density="compact" :append-icon="academicFlag ? 'mdi-chevron-up':'mdi-chevron-down'" style="font-size: 12px;">Academics</v-btn>
                </template>
            
                <v-list style="background-color:rgb(242, 255, 242);">
                      <v-list-item @click="updateMenu(item)" v-for="(item, index) in academicMenu" :key="index">
                        <v-list-item-title>
                            {{ item.title }}
                        </v-list-item-title>
                      </v-list-item>
                </v-list>
              </v-menu>
            <v-menu open-on-hover v-model="facultyFlag" >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" height="65" :append-icon="facultyFlag ? 'mdi-chevron-up':'mdi-chevron-down'" style="font-size: 12px;">faculty</v-btn>
                </template>
            
                <v-list style="background-color:rgb(242, 255, 242);">
                      <v-list-item @click="updateMenu(item)" v-for="(item, index) in facultyMenu"  :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                </v-list>
              </v-menu>
              <v-menu open-on-hover v-model="admissionFlag" >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" height="65" :append-icon="admissionFlag ? 'mdi-chevron-up':'mdi-chevron-down'" style="font-size: 12px;">Admissions</v-btn>
                </template>
            
                <v-list style="background-color:rgb(242, 255, 242);">
                      <v-list-item @click="updateMenu(item)" v-for="(item, index) in admissionMenu"  :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                </v-list>
              </v-menu>
              <v-menu open-on-hover v-model="campusFlag" >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" height="65" :append-icon="campusFlag ? 'mdi-chevron-up':'mdi-chevron-down'" style="font-size: 12px;">Campus</v-btn>
                </template>
            
                <v-list style="background-color:rgb(242, 255, 242);">
                      <v-list-item @click="updateMenu(item)" v-for="(item, index) in campusMenu"  :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                        <template v-slot:append v-if="item.subMenuFlag">
                          <v-icon icon="mdi-menu-right" size="x-small"></v-icon>
                        </template>
                          <v-menu :open-on-focus="false" activator="parent" open-on-hover submenu location="right" v-if="item.subMenuFlag">
                            <v-list style="background-color:rgb(242, 255, 242);">
                              <v-list-item @click="updateMenu(subMenuItem)" v-for="(subMenuItem,j) in item.subMenuContent" :key="j" link>
                                <v-list-item-title>{{ subMenuItem.title }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                      </v-list-item>
                </v-list>
              </v-menu>
              <v-menu open-on-hover v-model="facilitiesFlag" >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" height="65" :append-icon="facilitiesFlag ? 'mdi-chevron-up':'mdi-chevron-down'" style="font-size: 12px;">Extension Activities</v-btn>
                </template>
            
                <v-list style="background-color:rgb(242, 255, 242);">
                      <v-list-item @click="updateMenu(item)" v-for="(item, index) in facilitiesMenu"  :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                </v-list>
              </v-menu>
              <v-menu open-on-hover v-model="studentCornerFlag" >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" height="65" :append-icon="studentCornerFlag ? 'mdi-chevron-up':'mdi-chevron-down'" style="font-size: 12px;">Student Corner</v-btn>
                </template>
            
                <v-list style="background-color:rgb(242, 255, 242);">
                      <v-list-item @click="updateMenu(item)" v-for="(item, index) in studentCornerMenu"  :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                </v-list>
              </v-menu>
              <v-menu open-on-hover v-model="researchFlag" >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" height="65" :append-icon="researchFlag ? 'mdi-chevron-up':'mdi-chevron-down'" style="font-size: 12px;">Research</v-btn>
                </template>
            
                <v-list style="background-color:rgb(242, 255, 242);">
                      <v-list-item @click="updateMenu(item)" v-for="(item, index) in researchMenu"  :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                </v-list>
              </v-menu>
              <!-- <v-menu open-on-hover v-model="rti" > -->
                <!-- <template v-slot:activator="{ props }"> -->
                  <v-btn height="65" to="/rti" >RTI</v-btn>
                <!-- </template> -->
            
                <!-- <v-list style="background-color:rgb(242, 255, 242);">
                      <v-list-item @click="updateMenu(item)" v-for="(item, index) in iqacMenu"  :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                </v-list>
              </v-menu> -->
              <v-menu open-on-hover v-model="iqacFlag" >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" height="65" :append-icon="iqacFlag ? 'mdi-chevron-up':'mdi-chevron-down'" style="font-size: 12px;">NAAC</v-btn>
                </template>
            
                <v-list style="background-color:rgb(242, 255, 242);">
                      <v-list-item @click="updateMenu(item)" v-for="(item, index) in iqacMenu"  :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                        <template v-slot:append v-if="item.subMenuFlag">
                          <v-icon icon="mdi-menu-right" size="x-small"></v-icon>
                        </template>
                          <v-menu :open-on-focus="false" activator="parent" open-on-hover submenu location="right" v-if="item.subMenuFlag">
                            <v-list style="background-color:rgb(242, 255, 242);">
                              <v-list-item @click="updateMenu(subMenuItem)" v-for="(subMenuItem,j) in item.subMenuContent" :key="j" link>
                                <v-list-item-title>{{ subMenuItem.title }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                      </v-list-item>
                </v-list>
              </v-menu>
              <!-- <v-menu open-on-hover v-model="reportsFlag" >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" height="65" :append-icon="reportsFlag ? 'mdi-chevron-up':'mdi-chevron-down'">Reports</v-btn>
                </template>
            
                <v-list style="background-color:rgb(242, 255, 242);">
                      <v-list-item @click="updateMenu(item)" v-for="(item, index) in reportMenu"  :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                </v-list>
              </v-menu> -->
              <v-btn stacked to="/contact" style="font-size: 12px;">Contact us</v-btn>
            <v-spacer></v-spacer>
        </v-toolbar>
    </v-row>
    <v-navigation-drawer
        v-model="$store.state.navigationDrawer"
        temporary
        class="gradient-background"
      >
      <v-list-item to="/">
        <v-list-item-title class="ml-2">Home</v-list-item-title>
      </v-list-item>
      <v-expansion-panels>
        <v-expansion-panel elevation="0" class="gradient-background"  title="About us">
            <v-expansion-panel-text>
            <v-list style="background-color:rgb(242, 255, 242);">
                <v-list-item @click="updateMenu(item)" v-for="(item, index) in aboutUsMenu" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <template v-slot:append v-if="item.subMenuFlag">
                    <v-icon icon="mdi-menu-down" size="x-small"></v-icon>
                  </template>
                </v-list-item>
            </v-list>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel elevation="0" class="gradient-background"  title="Academics">
            <v-expansion-panel-text>
                <v-list style="background-color:rgb(242, 255, 242);">
                    <v-list-item @click="updateMenu(item)" v-for="(item, index) in academicMenu"  :key="index">
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
              </v-list>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel elevation="0" class="gradient-background"  title="Faculty">
            <v-expansion-panel-text>
            <v-list style="background-color:rgb(242, 255, 242);">
                <v-list-item @click="updateMenu(item)" v-for="(item, index) in facultyMenu" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel elevation="0" class="gradient-background"  title="Admissions">
            <v-expansion-panel-text>
            <v-list style="background-color:rgb(242, 255, 242);">
                <v-list-item @click="updateMenu(item)" v-for="(item, index) in admissionMenu" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel elevation="0" class="gradient-background"  title="Campus">
          <v-expansion-panel-text>
          <v-list style="background-color:rgb(242, 255, 242);">
              <v-list-item @click="updateMenu(item)" v-for="(item, index) in campusMenu" :key="index">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
          </v-list>
          </v-expansion-panel-text>
      </v-expansion-panel>
        <v-expansion-panel elevation="0" class="gradient-background"  title="Facilities">
            <v-expansion-panel-text>
            <v-list style="background-color:rgb(242, 255, 242);">
                <v-list-item @click="updateMenu(item)" v-for="(item, index) in facilitiesMenu" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel elevation="0" class="gradient-background"  title="Student Corner">
            <v-expansion-panel-text>
            <v-list style="background-color:rgb(242, 255, 242);">
                <v-list-item @click="updateMenu(item)" v-for="(item, index) in studentCornerMenu" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel elevation="0" class="gradient-background"  title="Research">
            <v-expansion-panel-text>
            <v-list style="background-color:rgb(242, 255, 242);">
                <v-list-item @click="updateMenu(item)" v-for="(item, index) in researchMenu" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <!-- <v-list> -->
        <!-- </v-list> -->
        <v-expansion-panel elevation="0" class="gradient-background"  title="NAAC">
            <v-expansion-panel-text>
            <v-list style="background-color:rgb(242, 255, 242);">
                <v-list-item @click="updateMenu(item)" v-for="(item, index) in iqacMenu" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <!-- <v-expansion-panel elevation="0" class="gradient-background"  title="Reports">
            <v-expansion-panel-text>
            <v-list style="background-color:rgb(242, 255, 242);">
                <v-list-item @click="updateMenu(item)" v-for="(item, index) in reportMenu" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-expansion-panel-text>
        </v-expansion-panel> -->
      </v-expansion-panels>
      <v-list-item to="/rti">
        <v-list-item-title class="ml-2">RTI</v-list-item-title>
      </v-list-item>
      <v-list-item to="/contact">
        <v-list-item-title class="ml-2">Contact Us</v-list-item-title>
      </v-list-item>
      </v-navigation-drawer>
</template>
<script>
export default {
    data() {
        return {
            aboutUsFlag: false,
            academicFlag: false,
            facultyFlag: false,
            admissionFlag: false,
            facilitiesFlag: false,
            studentCornerFlag: false,
            researchFlag: false,
            iqacFlag: false,
            reportsFlag: false,
            campusFlag: false,
            contactFlag: false,
            aboutUsMenu: [
                {title: 'About Us', key:'aboutUs', path:'/about', fullUrl: false, subMenuFlag: false},
                {title: 'Principal Desk', key:'pricipaldesk', path:'/principal_desk', fullUrl: false, subMenuFlag: false},
                {title: 'History', key:'history', path:'/history', fullUrl: false, subMenuFlag: false},
                {title: 'Vision And Mission', key:'VisionMission', path:'/visionnmission', fullUrl: false, subMenuFlag: false},
                {title: 'Organogram', key:'organogram', path:'/organogram', fullUrl: false, subMenuFlag: false},
                {title: 'Institutional Distinctiveness', key:'intDis', path:'/intdis', fullUrl: false, subMenuFlag: false},
                {title: 'Best Practice', key:'bestPra', path:'/best_practice', fullUrl: false, subMenuFlag: true, subMenuContent: [
                  {title: 'Green Campus', key:'gc', path:'/green_campus', fullUrl: false},
                  {title: 'Constitutional Awareness', key:'CA', path:'/constitutional_awareness', fullUrl: false},
                  {title: 'Nasha Mukti Practice', key:'NMP', path:'/nasha_mukti_practice', fullUrl: false},
                  
                ]},
                {title: 'Code of Conduct ', key:'intDis', path:'/cod', fullUrl: false, subMenuFlag: true, subMenuContent: [
                  {title: 'For Faculty', key:'forFaculty', path:'/cod_faculty', fullUrl: false},
                  {title: 'For Student', key:'forStudent', path:'/cod_student', fullUrl: false},
                ],},
                {title: 'College Newsletter', key:'CoN', path:'https://api.govtcollegekarauli.ac.in/storage/836/IQAC-Newsletter-GCK--2022-23-(01.01.2025).pdf', fullUrl: true},
                {title: 'College Committee', key:'CCT', path:'https://api.govtcollegekarauli.ac.in/storage/835/College-Committee.pdf', fullUrl: true},
                {title: 'Past Principals', key:'pastpricipal', path:'https://api.govtcollegekarauli.ac.in/principalDates.pdf', fullUrl: true},
                {title: 'NIRF', key:'NIRF', path:'/nirf', fullUrl: false},
                {title: 'AISHE', key:'AISHE', path:'/aishe', fullUrl: false},
                // {title: 'SWOC - Strength, Weaknesses, Opportunities & Challenges', key:'swoc', path:'/swoc', fullUrl: false, subMenuFlag: false},
            ],
            academicMenu: [
                {title: 'Courses Offered', key:'courses', path:'/courses_offered', fullUrl: false},
                {title: 'Course & Program Outcomes', key:'course', path:'https://api.govtcollegekarauli.ac.in/storage/943/COURSE-&-PROGRAM-OUTCOMES.pdf', fullUrl: true},
                {title: 'Academic Calendars', key:'ac', path:'/iqac/calenders', fullUrl: false},
                {title: 'Teaching Staff', key:'teachingstaff', path:'/teaching_staff', fullUrl: false},
                {title: 'Non Teaching Staff', key:'nonteachingstaff', path:'/non_teaching_staff', fullUrl: false},
            ],
            campusMenu: [
                {title: 'Cafeteria', key:'cafeteria', path:'/cafeteria', fullUrl: false},
                {title: 'Central Labs', key:'labs', path:'#', fullUrl: false, subMenuFlag: true, subMenuContent: [
                    {title: 'Botany Lab', key:'gc', path:'/botany_lab', fullUrl: false},
                    {title: 'Chemistry Lab', key:'gc', path:'/chemistry_lab', fullUrl: false},
                    {title: 'Geography Lab', key:'gc', path:'/geography_lab', fullUrl: false},
                    {title: 'ICT Lab', key:'gc', path:'/ict_lab', fullUrl: false},
                    {title: 'Physics Lab', key:'gc', path:'/physics_lab', fullUrl: false},
                    {title: 'Zoology Lab', key:'gc', path:'/zoology_lab', fullUrl: false},
                  ]},
                {title: 'Conference Hall', key:'cHalls', path:'/conference_hall', fullUrl: false},
                {title: "Girl's Common Room", key:'gCR', path:'/girls_common_room', fullUrl: false},
                {title: "Girl's Hostel", key:'gHostel', path:'/girls_hostel', fullUrl: false},
                {title: "Library", key:'library', path:'/library', fullUrl: false},
                {title: "Rain Water Harvesting", key:'rwh', path:'/rain_water_harvesting', fullUrl: false},
                {title: "Sports Arena", key:'rwh', path:'/sports_club', fullUrl: false},
            ],
            facultyMenu: [
                {title: 'Faculty Of Arts', key:'facultyofarts', path:'/subjects/1', fullUrl: false},
                {title: 'Faculty Of Science', key:'facultyofscience', path:'/subjects/2', fullUrl: false},
                {title: 'Faculty Of Commerce', key:'facultyofcommerce', path:'/subjects/3', fullUrl: false},
            ],
            admissionMenu: [
                {title: 'Admissions', key:'UG', path:'/admissions', fullUrl: false},
                {title: 'Admission Portal Link', key:'admissionPortalLink', path:'https://dceapp.rajasthan.gov.in/', fullUrl: true},
                {title: 'Admission Committee', key:'admissionCommittee', path:'https://api.govtcollegekarauli.ac.in/storage/705/WhatsApp-Image-2024-07-07-at-18.54.04.pdf', fullUrl: true},
                {title: 'Admission Policy', key:'admissionPolicy', path:'https://api.govtcollegekarauli.ac.in/storage/690/admission-policy-2024-2025.pdf', fullUrl: true},
                {title: 'Fee Structure 2024-25', key:'feeStrucute', path:'https://api.govtcollegekarauli.ac.in/storage/833/Fee-Structure-For-All-Students-2024-25.pdf', fullUrl: true},
                {title: 'Admission Prospectus 2023-24', key:'admissionProspectus', path:'https://api.govtcollegekarauli.ac.in/Prospectus+2023-24.pdf', fullUrl: true},
                {title: 'Seat Matrix 2024-25', key:'seatMatrix', path:'https://api.govtcollegekarauli.ac.in/storage/832/Seat-Matrix-2024-25-UG-I.pdf', fullUrl: true},
            ],
            studentCornerMenu: [
                {title: 'Circulars', key:'aboutUs', path:'/circular', fullUrl: false},
                {title: 'Scholarships', key:'scholarships', path:'/scholarships', fullUrl: false},
                {title: 'Code Of Ethics', key:'coe', path:'/coe', fullUrl: false},
                {title: 'Events Gallery', key:'events', path:'/events', fullUrl: false, subMenuFlag: false},
                // {title: 'E-Resources', key:'resources', path:'/resources', fullUrl: false},
                {title: 'Important Forms', key:'forms', path:'/forms', fullUrl: false},
                {title: 'Syllabus', key:'syllabus', path:'https://www.uok.ac.in/Syllabus-2022', fullUrl: true},
                {title: 'ICC', key:'ICC', path:'https://api.govtcollegekarauli.ac.in/storage/944/ICC-Text.pdf', fullUrl: true},
                {title: 'Anti Ragging Cell', key:'ICC', path:'https://api.govtcollegekarauli.ac.in/storage/945/ANTI-RAGGING-CELL-Text.pdf', fullUrl: true},
                {title: 'Student Feedback', key:'survey', path:'  https://forms.gle/nkJWwzKJGdJaaxGV7', fullUrl: true},
                // {title: 'MOOC Course', key:'mooc', path:'/mooc', fullUrl: false},
            ],
            facilitiesMenu: [
                {title: 'Academic & Literary Cell', key:'ALC', path:'/academic_and_literary_cell', fullUrl: false},
                {title: 'Alumini CELL', key:'AC', path:'/alumni_cell', fullUrl: false},
                {title: 'Cultural CELL', key:'CC', path:'/cultural_cell', fullUrl: false},
                {title: 'Innovation & Skill Developmet Cell', key:'ISDC', path:'/innovation_and_skill_development_cell', fullUrl: false},
                {title: 'NCC', key:'ncc', path:'/ncc', fullUrl: false},
                {title: 'NSS National Service Scheme', key:'nss', path:'/nss', fullUrl: false},
                {title: 'Placement Cell', key:'Pc', path:'/placement_cell', fullUrl: false},
                {title: 'Scout And Guide', key:'SG', path:'/scout_and_guide', fullUrl: false},
                {title: 'Sports', key:'sports', path:'/sports_and_yoga', fullUrl: false},
                {title: 'Women Cell', key:'library', path:'/women_cell', fullUrl: false},
                {title: 'Electoral Literacy Club', key:'elc', path:'/elc', fullUrl: false},
                {title: 'Open University Centers', key:'ouc', path:'/ouc', fullUrl: false},
                {title: 'Incubation Cell', key:'IC', path:'https://api.govtcollegekarauli.ac.in/storage/955/INCUBATION-CELL.pdf', fullUrl: true},
            ],
            researchMenu: [
              {title: 'Research Supervisors', key:'supervisors', path:'https://api.govtcollegekarauli.ac.in/storage/829/Research-Supervisor.pdf', fullUrl: true},
              {title: 'Research Scholars', key:'scholars', path:'https://api.govtcollegekarauli.ac.in/storage/830/Research-Scholar.pdf', fullUrl: true},
              {title: 'Research', key:'research', path:'https://api.govtcollegekarauli.ac.in/storage/834/ResearchPdf.pdf', fullUrl: true},
            ],
            iqacMenu: [
              {title: 'All Data', key:'allData', path:'/iqac_all', fullUrl: false},
              {title: 'Committee', key:'iqCommitee', path:'/iqac/commitee', fullUrl: false},
              {title: 'AQAR', key:'aqar', path:'/iqac/aqar', fullUrl: false},
              {title: 'SSR', key:'ssr', path:'/iqac/ssr', fullUrl: false},
              {title: 'Meeting Minutes', key:'metting_minutes', path:'/iqac/mm', fullUrl: false},
              {title: 'Best Practices', key:'bp', path:'/iqac/best_practices', fullUrl: false},
              {title: 'NAAC', key:'ac', path:'/naac', fullUrl: false},
              {title: 'Perspective Plan', key:'PP', path:'https://api.govtcollegekarauli.ac.in/storage/946/Perspective-plan-of-the-college-soft-copy---Copy.pdf', fullUrl: true},
              {title: 'IQAC Calendar', key:'ac', path:'#', fullUrl: false, subMenuFlag: true, subMenuContent: [
                {title: '2022-23', key:'ac1', path:'https://api.govtcollegekarauli.ac.in/storage/857/IQAC-Calendar-2022-23.pdf', fullUrl: true},
                {title: '2023-24', key:'ac2', path:'https://api.govtcollegekarauli.ac.in/storage/858/IQAC-Calendar-2023-24.pdf', fullUrl: true},
                {title: '2024-25', key:'ac3', path:'https://api.govtcollegekarauli.ac.in/storage/859/IQAC-Calendar-2024-25.pdf', fullUrl: true},
              ]},
              {title: 'IQAC Newsletter', key:'ac', path:'#', fullUrl: false, subMenuFlag: true, subMenuContent: [
                {title: '2022-23', key:'IN1', path:'https://api.govtcollegekarauli.ac.in/storage/836/IQAC-Newsletter-GCK--2022-23-(01.01.2025).pdf', fullUrl: true},
                {title: '2023-24', key:'IN2', path:'https://api.govtcollegekarauli.ac.in/storage/947/IQAC-Newsletter-GCK--2023-24.pdf', fullUrl: true},
              ]},
            ],
            reportMenu: [
                {title: 'Academic Activity Reports', key:'iqCommitee', path:'/iqCommitee', fullUrl: false},
                {title: 'NSS Activity Reports', key:'nssActivityReport', path:'/nss_activity_report', fullUrl: false},
            ],
        }
    },
    methods: {
        updateMenu (item) {
          if (item.fullUrl) {
            window.open(item.path)
          } else {
            this.$router.push(item.path)
          }
        }
    },
}
</script>
<style scoped>
.gradient-background {
  background: linear-gradient(to right, #ffffff, #deffce);
}
.scrollable-toolbar {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */
}

/* Hide scrollbar for Webkit browsers */
.scrollable-toolbar::-webkit-scrollbar {
  display: none;
}
</style>

import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/principal_desk',
    name: 'prncipalDesk',
    component: () => import('../views/PrincipalDesk.vue')
  },
  {
    path: '/past_principals',
    name: 'pastPrincipal',
    component: () => import('../views/PastPrincipal.vue')
  },
  {
    path: '/cod_faculty',
    name: 'codeOfConductTeacher',
    component: () => import('../views/CodeOfConduct.vue')
  },
  {
    path: '/cod_student',
    name: 'codeOfConductStudent',
    component: () => import('../views/CodeOfConductStudent.vue')
  },
  {
    path: '/organogram',
    name: 'Organogram',
    component: () => import('../views/Organogram.vue')
  },
  {
    path: '/events',
    name: 'EventGallery',
    component: () => import('../views/EventGallery.vue')
  },
  {
    path: '/naac',
    name: 'NaacView',
    component: () => import('../views/Naac.vue')
  },
  {
    path: '/event/:id',
    name: 'EventDetails',
    component: () => import('../views/EventDetails.vue'),
    props: true
  },
  {
    path: '/departments',
    name: 'Department',
    component: () => import('../views/DepartmentTable.vue')
  },
  {
    path: '/elc',
    name: 'ELCView',
    component: () => import('../views/ELCView.vue')
  },
  {
    path: '/faculties/:id',
    name: 'Faculties',
    component: () => import('../views/Faculties.vue'),
    props: true
  },
  {
    path: '/subjects/:id',
    name: 'Subject',
    component: () => import('../views/Subject.vue'),
    props: true
  },
  {
    path: '/rti',
    name: 'RTI',
    component: () => import('../views/RTI.vue'),
    props: true
  },
  {
    path: '/comittees',
    name: 'Comittee',
    component: () => import('../views/Departments.vue')
  },
  {
    path: '/admissions',
    name: 'Admission',
    component: () => import('../views/Admissions.vue')
  },
  {
    path: '/circular',
    name: 'Circular',
    component: () => import('../views/Circulars.vue')
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('../views/History.vue')
  },
  {
    path: '/visionnmission',
    name: 'VisionMission',
    component: () => import('../views/VisionMission.vue')
  },
  {
    path: '/intdis',
    name: 'IntDis',
    component: () => import('../views/IntDis.vue')
  },
  {
    path: '/non_teaching_staff',
    name: 'NonTeachingStaff',
    component: () => import('../views/NonTeachingStaff.vue')
  },
  {
    path: '/teaching_staff',
    name: 'TeachingStaff',
    component: () => import('../views/TeachingStaff.vue')
  },
  {
    path: '/ouc',
    name: 'OUC',
    component: () => import('../views/Ouc.vue')
  },
  {
    path: '/scholarships',
    name: 'Scholarship',
    component: () => import('../views/Scholarship.vue')
  },
  {
    path: '/nss',
    name: 'NSS',
    component: () => import('../views/NSS.vue')
  },
  {
    path: '/contact',
    name: 'ContactUs',
    component: () => import('../views/ContactUs.vue')
  },
  {
    path: '/iqac/:name',
    name: 'IQAC',
    component: () => import('../views/IQACType.vue'),
    props: true
  },
  {
    path: '/iqac_all',
    name: 'IQACAll',
    component: () => import('../views/IQAC.vue'),
  },
  {
    path: '/courses_offered',
    name: 'CoursesOffered',
    component: () => import('../views/CoursesOffered.vue'),
  },
  {
    path: '/practical_fee',
    name: 'PracticalFee',
    component: () => import('../views/PracticalFee.vue'),
  },
  {
    path: '/practical_reciept/:txnId',
    name: 'PracticalFeeReciept',
    props: true,
    component: () => import('../views/PracticalFeeReceipt.vue'),
  },
  {
    path: '/best_practice',
    name: 'BestPractice',
    component: () => import('../views/BestPractice.vue'),
  },
  {
    path: '/green_campus',
    name: 'GreenCampus',
    component: () => import('../views/GreenCampus.vue'),
  },
  {
    path: '/constitutional_awareness',
    name: 'ConstitutionalAwareness',
    component: () => import('../views/ConstitutionalAwareness.vue'),
  },
  {
    path: '/nasha_mukti_practice',
    name: 'NashaMuktiPractice',
    component: () => import('../views/NashaMuktiPractice.vue'),
  },
  {
    path: '/cafeteria',
    name: 'Cafeteria',
    component: () => import('../views/Cafeteria.vue'),
  },
  {
    path: '/botany_lab',
    name: 'BotanyLab',
    component: () => import('../views/BotanyLab.vue'),
  },
  {
    path: '/chemistry_lab',
    name: 'ChemistryLab',
    component: () => import('../views/ChemistryLab.vue'),
  },
  {
    path: '/geography_lab',
    name: 'GeographyLab',
    component: () => import('../views/GeographyLab.vue'),
  },
  {
    path: '/ict_lab',
    name: 'ICTLab',
    component: () => import('../views/ICTLAB.vue'),
  },
  {
    path: '/physics_lab',
    name: 'PhysicsLab',
    component: () => import('../views/PhysicsLab.vue'),
  },
  {
    path: '/zoology_lab',
    name: 'ZoologyLab',
    component: () => import('../views/ZoologyLab.vue'),
  },
  {
    path: '/conference_hall',
    name: 'ConferenceHall',
    component: () => import('../views/ConferenceHall.vue'),
  },
  {
    path: '/girls_common_room',
    name: 'GirlsCommonRoom',
    component: () => import('../views/GirlsCommonRoom.vue'),
  },
  {
    path: '/girls_hostel',
    name: 'GirlsHostel',
    component: () => import('../views/GirlsHostel.vue'),
  },
  {
    path: '/library',
    name: 'Library',
    component: () => import('../views/Library.vue'),
  },
  {
    path: '/rain_water_harvesting',
    name: 'RAINWATERHARVESTING',
    component: () => import('../views/RainWaterHarvesting.vue'),
  },
  {
    path: '/sports_club',
    name: 'SportsClub',
    component: () => import('../views/SportsClub.vue'),
  },
  {
    path: '/academic_and_literary_cell',
    name: 'AcademicAndLiteraryCell',
    component: () => import('../views/AcademicAndLiteraryCell.vue'),
  },
  {
    path: '/alumni_cell',
    name: 'AlumniCell',
    component: () => import('../views/AlumniCell.vue'),
  },
  {
    path: '/cultural_cell',
    name: 'CulturalCell',
    component: () => import('../views/CulturalCell.vue'),
  },
  {
    path: '/innovation_and_skill_development_cell',
    name: 'InnovationAndSkillDevelopmentCell',
    component: () => import('../views/InnovationAndSkillDevelopmentCell.vue'),
  },
  {
    path: '/ncc',
    name: 'NCC',
    component: () => import('../views/NCC.vue'),
  },
  {
    path: '/nirf',
    name: 'NIRF',
    component: () => import('../views/NIRF.vue'),
  },
  {
    path: '/aishe',
    name: 'AISHE',
    component: () => import('../views/AISHE.vue'),
  },
  {
    path: '/placement_cell',
    name: 'PlacementCell',
    component: () => import('../views/PlacementCell.vue'),
  },
  {
    path: '/scout_and_guide',
    name: 'ScoutAndGuide',
    component: () => import('../views/ScoutAndGuide.vue'),
  },
  {
    path: '/sports_and_yoga',
    name: 'SportsAndYoga',
    component: () => import('../views/Sports.vue'),
  },
  {
    path: '/women_cell',
    name: 'WomenCell',
    component: () => import('../views/WomenCell.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
  <v-row style="background-color: beige;">
  </v-row>
  <v-row>
    <v-col lg="8" md="7" cols="12"  v-if="!bannerLoading">
      <v-card :max-height="($vuetify.display.sm || $vuetify.display.xs) ? '250':'500'" elevation="0">
        <v-carousel hide-delimiters="" cycle="" :show-arrows="false">
          <v-carousel-item v-for="(item,i) in banners" :key="i"  >
            <v-img :src="item.url" cover="" :lazy-src="item.thumb_url" aspect-ratio="2"></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-card>
      </v-col>
    <v-col lg="4" md="5" cols="12">
      <v-card max-height="500" elevation="0" max-width="600" class="mt-2">
        <v-card-title>Notifications</v-card-title>
        <v-card-text>
          <Vue3Marquee  :vertical="true" :pauseOnHover="true" :animateOnOverflow="true" delay="1" :clone="false" :loop="0" :duration="15" >
            <v-card elevation="0" class="text-wrapper">
              <v-card-actions class="gradient-heading"  v-for="(notification, i) in notifications" :key="i">
                <a v-if="notification.link != null" :href="notification.link" target="__blank" class="gradient-heading text-subtitle-1">{{ notification.title }}</a>
                <a v-else class="gradient-heading text-subtitle-1">{{ notification.title }}</a>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
            <!-- <h4  ></h4> -->
        </Vue3Marquee>
        </v-card-text>
      </v-card>
      <!-- <v-card max-height="300" max-width="600" class="mt-2">
        <v-card-title>Notice Board</v-card-title>
        <v-card-text>
          <Vue3Marquee :vertical="true" :loop="0">
            <h3 class="mt-4 gradient-heading text-left" v-for="(notification, i) in notifications" :key="i" >
              {{ notification.title}}
            </h3>
        </Vue3Marquee>
        </v-card-text>
      </v-card> -->
    </v-col>
  </v-row>
  <v-row style="background-color: rgb(216, 255, 219)246, 252);" >
    <v-col cols="12" class="text-center mt-12">
      <h1 class="font-weight-light">Overview</h1>
    </v-col>
    <v-col lg="2" md="1"></v-col>
    <v-col lg="8" cols="12" class="text-center">
      <v-container>
        <h3 class="font-weight-light text-center">
          About the college- Government College, Karauli is one of the most prestigious educational institutions in Rajasthan. The College was founded in the year of 1960. Presently the institute is affiliated with the university of Kota, Kota. Karauli is situated on the bank of the river Barkhera and Bhadrawati. It is a religious city which is known for the temple of Madan Mohan Ji and Kailadevi. It is the oldest college of the district. The college is imparting education in all three streams of Science, Humanities, Commerce at undergraduate and postgraduate levels. Besides these three streams, the College makes many skills development and vocational courses run. The institution also provides research facilities.It is the education centre of the DANG Region of Karauli district where most of students come from rural and interior areas of the district to study. The institution is accredited with NAAC-UGC grading. The college is declared as Nodal College and resource center by the Government of Rajasthan and centre for equal opportunity by UGC.
        </h3>
        <h3 class="font-weight-light text-center mt-4">
          ​​At first the college began with the stream of Arts in the beginning year. Then the stream of science started in 1962 and that of Commerce commenced in the year of 1970. After this PG Course M.Com. started in Business Administration and M.A. in Political Science in the session 1977-78. M.Com. in EAFM began during the session of 1981-82 and so is in M. Com in ABST in 1980-81. M.Com classes began in the College. M.A. in History was started in the year of 1982-83. M.Sc. in Botany commenced in the session of 1989-90. The stream of Arts flourished with M.A. in Geography in the year of 2017-18. Today the college is one of the premier institutions of Rajasthan where 17subjects are being taught at graduate level and 07 subjects are being taught at postgraduate level. The institute has a research centre under the university of Kota, Kota. The institute is thriving steadily in academics and extra-curricular activities.

        </h3>
      </v-container>
    </v-col>
    <v-col lg="2" md="1"></v-col>
  </v-row>
  <v-row class="mt-12">
    <v-col cols="12" v-if="$vuetify.display.lg || $vuetify.display.md || $vuetify.display.xl">
      <v-parallax class="gradient-background" src="../assets/college_bg_7.jpg">
        <v-row justify="center" align="center" style="height: 70vh;">
          <v-col lg="3" md="2" sm="3" cols="12" class="text-center">
            <v-icon class="mt-16"  size="120" color="grey-lighten-4" >mdi-account-group</v-icon>
            <h1 class="text-center" style="color: white; position:relative;">{{studentCount}}+</h1>
            <p  class="text-subtitle-1" style="color: white; position:relative;">Students</p>
          </v-col>
          <v-col lg="3" md="2" sm="3" cols="12" class="text-center">
            <v-icon class="mt-16" size="120" color="grey-lighten-4" >mdi-account-tie-outline</v-icon>
            <h1 class="text-center" style="color: white; position:relative;">{{facultyCount}}+</h1>
            <p  class="text-subtitle-1" style="color: white; position:relative;">Faculty</p>
          </v-col>
          <v-col lg="3" md="2" sm="3" cols="12" class="text-center">
            <v-icon class="mt-16" size="120" color="grey-lighten-4" >mdi-thumb-up-outline</v-icon>
            <h1 class="text-center" style="color: white; position:relative;">{{years}}</h1>
            <p  class="text-subtitle-1" style="color: white; position:relative;">Years</p>
          </v-col>
          <v-col lg="3" md="2" sm="3" cols="12" class="text-center">
            <v-icon class="mt-16" size="120" color="grey-lighten-4" >mdi-domain</v-icon>
            <h1 class="text-center" style="color: white; position:relative;">{{departmentCount}}</h1>
            <p  class="text-subtitle-1" style="color: white; position:relative;">Departments</p>
          </v-col>
        </v-row>
      </v-parallax>
    </v-col>
    <v-col cols="12" v-else>
      <v-parallax class="gradient-background" src="../assets/college_bg_7.jpg">
        <v-row>
          <v-col cols="6" xs="12" class="text-center">
            <v-icon class="mt-16" style="height: 20vh;" size="120" color="grey-lighten-4" >mdi-account-group</v-icon>
            <h2 style="color: white; position:relative;">{{studentCount}}+</h2>
            <p  class="text-subtitle-1" style="color: white; position:relative;">Students</p>
          </v-col>
          <v-col cols="6" xs="12" class="text-center">
            <v-icon class="mt-16" style="height: 20vh;" size="120" color="grey-lighten-4" >mdi-account-tie-outline</v-icon>
            <h2 style="color: white; position:relative;">{{facultyCount}}+</h2>
            <p  class="text-subtitle-1" style="color: white; position:relative;">Faculty</p>
          </v-col>
          <v-col cols="6" xs="12" class="text-center">
            <v-icon class="mt-16" style="height: 20vh;" size="120" color="grey-lighten-4" >mdi-thumb-up-outline</v-icon>
            <h2 style="color: white; position:relative;">{{years}}</h2>
            <p  class="text-subtitle-1" style="color: white; position:relative;">Years</p>
          </v-col>
          <v-col cols="6" xs="12" class="text-center">
            <v-icon class="mt-16" style="height: 20vh;" size="120" color="grey-lighten-4" >mdi-domain</v-icon>
            <h2 style="color: white; position:relative;">{{departmentCount}}</h2>
            <p  class="text-subtitle-1" style="color: white; position:relative;">Departments</p>
          </v-col>
        </v-row>
      </v-parallax>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <v-container>
      <v-card elevation="0" max-height="1600" >
        <v-card-text>
          <v-row :class="($vuetify.display.lg || $vuetify.display.md || $vuetify.display.xl) ? 'ma-16':''">
            <v-col cols="12" lg="3" md="4" sm="12" class="text-right">
              <v-img max-width="300" src="../assets/principal.jpeg" ></v-img>
            </v-col>
            <v-col cols="12" lg="7" md="6" sm="12">
              <h1>Dr. V L Bairwa</h1>
              <h2 class="mt-4">Principal</h2>
              <p class="text-subtitle-1 mt-4">
                For over six decades, Government College Karauli has stood as a beacon of knowledge, nurturing young minds and fostering a culture of learning. Our institution has been a crucible of transformative education, not just providing degrees but sculpting individuals ready to face the challenges of tomorrow.
              </p>
              <p class="text-subtitle-1">
                The passage of time has witnessed the evolution of our courses, and today, we proudly offer a range of comprehensive postgraduate programs, empowering our students to delve deeper into their fields of interest and contribute meaningfully to society. <v-btn variant="text" v-if="viewMore == false && ($vuetify.display.sm || $vuetify.display.xs)" @click="viewMore = true">View More</v-btn>
              </p>
              <p class="text-subtitle-1" v-if="($vuetify.display.lg || $vuetify.display.xl || $vuetify.display.xxl || $vuetify.display.md)">
                In addition to our robust postgraduate programs, Government College Karauli is committed to holistic development, offering a plethora of extracurricular activities, internships, and community engagement initiatives. Through these opportunities, students not only enhance their academic knowledge but also cultivate essential life skills such as leadership, teamwork, and communication. Our dedicated faculty members serve as mentors, guiding students through their academic journey and instilling in them a passion for lifelong learning.
              </p>
              <p class="text-subtitle-1" v-else-if="viewMore">
                In addition to our robust postgraduate programs, Government College Karauli is committed to holistic development, offering a plethora of extracurricular activities, internships, and community engagement initiatives. Through these opportunities, students not only enhance their academic knowledge but also cultivate essential life skills such as leadership, teamwork, and communication. Our dedicated faculty members serve as mentors, guiding students through their academic journey and instilling in them a passion for lifelong learning.
                <v-btn variant="text" v-if="viewMore" @click="viewMore = false">View Less</v-btn>
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    </v-col>
  </v-row>
  <v-row style="background-color: rgb(240, 255, 244)255, 240);">
    <v-col cols="12">
      <imageSlider></imageSlider>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" class="text-center mt-2">
      <a class="mt-4 gradient-heading" style="text-decoration: none;" href="https://www.eci.gov.in/voter-helpline-app/">Voter Helpline Registration</a>
    </v-col>
  </v-row>
  <!-- <v-row>
    <v-col cols="12">
      <h1 class="font-weight-light text-center">News And Events</h1>
    </v-col>
  </v-row> -->
  <v-dialog v-model="bannerLoading" :fullscreen="true">
    <v-card>
      <v-card-title>Loading...</v-card-title>
      <div class="center">
        <dotlottie-player src="https://lottie.host/105e18a0-9178-413c-9d5c-6cf1c27f6abe/PnLD7Gl7CN.json" background="transparent" speed="2" style="width: 500px; height: 300px;" loop autoplay></dotlottie-player>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from 'vue';
import imageSlider from '../components/ImageSlider.vue'
import { APIService } from '../Utils/APIService'
const apiService = new APIService()
export default defineComponent({
  name: 'HomeView',
  components: {
    imageSlider 
  },
  data() {
    return {
      banners: [],
      dialog: true,
      notifications: [],
      images: [],
      studentCount: 3400,
      viewMore: false,
      facultyCount: 40,
      departmentCount: 17,
      years: 63,
      errors: [],
      bannerLoading: true
    }
  },
  methods: {
    getCirculars() {
      apiService.getCirculars().then((response) => {
      if (response.status === 200) {
          this.loading = false
          this.notifications = response.data.data.data
          const blankJson = {}
          blankJson.title = ''
          blankJson.link = ''
          this.notifications.push(blankJson)
          this.notifications.push(blankJson)
          this.notifications.push(blankJson)
          this.notifications.push(blankJson)
          this.notifications.push(blankJson)
          this.notifications.push(blankJson)
      }
      })
      .catch(e => {
        this.errors.push(e)
        this.loading = false
      })
    },
    getBanners() {
      this.bannerLoading = true
      apiService.getBanners().then((response) => {
      if (response.status === 200) {
        this.loading = false
        this.banners = response.data.data
        this.bannerLoading = false
      }
      })
      .catch(e => {
        this.errors.push(e)
        this.loading = false
      })
    },
    viewNotif(notification) {
      if (notification.link != null)
        window.open(notification.link, '__blank')
    },
    counter() {
      this.studentCount += 1
      this.facultyCount += 1
      this.departmentCount += 1
      this.years += 1
      this.increaseCounter()
      // if (this.studentCount == 3400 && this.facultyCount == 40 && this.departmentCount == 17 && this.years == 63)
      // else
        // this.counter()
    },
    increaseCounter () {
      const intervalId = setInterval(this.counter(), 100);
    }
  },
  beforeMount() {
    this.getBanners()
  },
  mounted() {
    this.getCirculars()
  },
});
</script>
<style scoped>
.gradient-background {
  color: linear-gradient(to right, rgb(175, 216, 26), #3b3c3a);
}
.text-wrapper {
  max-width: 600px;
  white-space: normal;
  word-wrap: break-word;
}

.gradient-heading {
  /* Creating a linear gradient for the text */
  background: linear-gradient(to right,#ff00cc ,#dfb200);
  -webkit-background-clip: text; /* Clip the background to the text */
  -webkit-text-fill-color: transparent; /* Make the text transparent */
}
.v-parallax {
  height: 700px; /* Adjust height as needed */
}
.custom-heading {
  color: green;
}
.left-text {
  text-align: left; /* Align text to the left */
  display: inline-block; /* Ensures div width adjusts to content */
  /* Or you can use display: inline; depending on your layout needs */
}
/* Apply black gradient as the background */
.v-parallax::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0.7)); /* Adjust gradient colors and opacity */
} 
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed */
}

/* Optional: Add some margin or padding */
body {
  margin: 0;
  padding: 0;
}
</style>